import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/wenke/wenke/src/components/layout.js";
import { Container } from '../components/container';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container mdxType="Container">
      <h1>{`Allgemeine Geschäftsbedingungen`}</h1>
      <h3>{`§ 1 Allgemeines – Anwendungsbereich`}</h3>
      <p>{`(1) Die Allgemeinen Geschäftsbedingungen (AGB) der Auftragnehmerin sind
Vertragsbestandteil und gelten ausschließlich. Entgegenstehende oder von den AGB der
Auftragnehmerin abweichende Bedingungen des Kunden erkennt die Auftragnehmerin nicht
an, sofern die Auftragnehmerin deren Geltung nicht explizit zugestimmt hat. Die
Auftragnehmerin erkennt abweichende Bedingungen auch dann nicht an, wenn die
Auftragnehmerin in Kenntnis entgegenstehender oder abweichender Bedingungen des
Kunden den Auftrag vorbehaltlos ausführt.`}</p>
      <p>{`(2) Die AGB der Auftragnehmerin gelten nur gegenüber Unternehmern.`}</p>
      <p>{`(3) Sämtliche Vereinbarungen aus dem Vertrag sind in Textform niederzulegen. Mündliche
Vereinbarungen sind nur verbindlich, wenn die Auftragnehmerin sie in Textform bestätigt
hat.`}</p>
      <h3>{`§ 2 Angebot - Leistungsinhalt`}</h3>
      <p>{`(1) Die Angebote der Auftragnehmerin sind freibleibend, sofern nicht ein Anderes vereinbart
ist.`}</p>
      <p>{`(2) Soweit der Kunde Aufträge an die Auftragnehmerin mündlich erteilt, sind diese bindend.
Die Auftragnehmerin hat Anspruch darauf, dass der Kunde mündlich erteilte Aufträge
unverzüglich in Textform bestätigt. Ein Auftrag gilt insoweit als erteilt, wenn die
Auftragnehmerin vor einer Einigung über alle Punkte eines Auftrages in Kenntnis des Kunden
mit einem Teil der Auftragsdurchführung beginnt, ohne dass der Kunde widerspricht. Ein
Auftrag kann durch die Auftragnehmerin auch durch Ausführung der Tätigkeit angenommen
werden, wenn über alle Punkte eines Auftrages bereits Klarheit hergestellt ist.`}</p>
      <p>{`(3) Gegenstand der Tätigkeit der Auftragnehmerin ist immer die vereinbarte Dienstleistung
und nicht die Herbeiführung eines bestimmten wirtschaftlichen Erfolges.`}</p>
      <h3>{`§ 3 Preise, Zahlungsbedingungen, Aufrechnung, Zurückbehaltung`}</h3>
      <p>{`(1) Alle Preise gegenüber Unternehmern sind Nettopreise zuzüglich der jeweils gültigen
gesetzlichen Mehrwertsteuer, soweit diese anfällt und nicht ein anderes vereinbart ist. Bei
längerer Vertragsdauer hat die Auftragnehmerin das Recht, eine Anpassung der Vergütung
geltend zu machen, der Kunde ist verpflichtet, mit der Auftragnehmerin hierüber in
Verhandlungen zu treten.`}</p>
      <p>{`(2) Soweit nicht ein anderes vereinbart ist, ist die Leistung der Auftragnehmerin nach Zeit
abzurechnen. Der Stundenlohn der Auftragsnehmerin ergibt sich aus dem Auftrag und ist
zuzüglich Spesen und Auslagen in nachgewiesener und erforderlicher Höhe zu zahlen.
Ansonsten sind Leistungen durch den Kunden nach den ortsüblichen und angemessenen
Stundensätzen zuzüglich erforderliche Spesen und Auslagen zu vergüten.`}</p>
      <p>{`(3) Ist ein Paket- oder ein Pauschalpreis vereinbart gilt dieser, soweit nach der Vereinbarung
oder den Umständen die Leistungspflicht der Auftragnehmerin definiert ist. Leistungen, die
nicht in dem jeweiligen Paket oder der jeweiligen Pauschale enthalten sind, hat der Kunde
zusätzlich nach den vertraglich vereinbarten, ersatzweise den ortsüblichen und angemessenen
Stundensätzen zuzüglich erforderliche Spesen und Auslagen zu vergüten.`}</p>
      <p>{`(4) Die Zahlung des Kunden ist sofort fällig. Der Kunde wird darauf hingewiesen, dass er
spätestens 30 Tage nach Zugang der Rechnung in Verzug gerät. Sofern der Kunde mit der
Zahlung in Verzug ist, ist er verpflichtet, für jede weitere Mahnung pauschal 2,00 € für
Aufwendungen zu erstatten.`}</p>
      <p>{`(5) Der Kunde kann nur aufrechnen, wenn seine Gegenansprüche rechtskräftig festgestellt,
unbestritten oder von der Auftragnehmerin anerkannt sind oder das Aufrechnungsrecht auf
Rechten des Kunden wegen nicht vollständiger oder mangelhafter Leistung aus demselben
Vertragsverhältnis beruht.`}</p>
      <p>{`(6) Zur Ausübung eines Zurückbehaltungsrechts ist die Auftragnehmerin wegen sämtlicher
Forderungen aus der Geschäftsverbindung zu dem Kunden befugt`}</p>
      <h3>{`§ 4 Leistungserbringung, Leistungszeit, Verzug, Höhere Gewalt`}</h3>
      <p>{`(1) Die Auftragnehmerin ist berechtigt, Subunternehmer einzuschalten und den Auftrag ganz
oder teilweise an Dritte weiterzugeben.`}</p>
      <p>{`(2) Der Kunde kann einer Weitervergabe jedoch widersprechen, wenn dadurch seine
schutzwürdigen Interessen beeinträchtigt werden.`}</p>
      <p>{`(3) Der Beginn einer eventuell angegebenen Leistungszeit setzt die Abklärung aller
technischen, rechtlichen und gestalterischen Fragen und die rechtzeitige und ordnungsgemäße
Erfüllung der Verpflichtungen des Kunden voraus. Die Einrede des nicht erfüllten Vertrages
für die Auftragnehmerin bleibt vorbehalten.`}</p>
      <p>{`(4) Liefer- und Leistungszeiten als auch deren Änderungen sind nur dann verbindlich, wenn
sie im Vertrag vereinbart wurden.`}</p>
      <p>{`(5) Sofern die Auftragnehmerin für ihre Leistungserbringung auf Leistungen eines oder
mehrerer Vorlieferanten angewiesen ist, gelten vereinbarte Leistungsfristen vorbehaltlich
einer fristgerechten Selbstbelieferung durch die Vorlieferanten der Auftragnehmerin. Der
Vorbehalt gilt nicht für solche Verzögerungen, die die Auftragnehmerin selbst zu vertreten
hat.`}</p>
      <p>{`(6) Höhere Gewalt oder bei der Auftragnehmerin oder den Subunternehmern der
Auftragnehmerin eintretende Betriebsstörungen, z. B. durch Aufruhr, Streik oder
Aussperrung, die die Auftragnehmerin ohne eigenes Verschulden vorübergehend daran
hindert, die Leistung zu einem eventuell vereinbarten Termin oder innerhalb einer eventuell
vereinbarten Frist zu liefern, verändern die Leistungszeiten um die Dauer der durch die
Umstände bedingten Leistungsstörung. Führt eine entsprechende Störung zu einem
Leistungsaufschub von mehr als zwei Wochen oder fällt schon vorher das Interesse des
Kunden an der Vertragserfüllung objektiv weg, ist der Kunde berechtigt, vom Vertrag
zurückzutreten.`}</p>
      <h3>{`§ 5 Pflichten des Kunden`}</h3>
      <p>{`(1) Der Kunde ist verpflichtet, der Auftragnehmerin vor Auftragserteilung alle Gesetze,
Normen und sonstigen Vorschriften zu nennen, die die Auftragnehmerin für die Erbringung
der Leistung beachten soll. Eine rechtliche Beratung oder Überprüfung nimmt die
Auftragnehmerin nicht vor. Dafür ist vom Kunden ein Rechtsanwalt einzuschalten.`}</p>
      <p>{`(2) Der Kunde ist verpflichtet, der Auftragnehmerin rechtzeitig die für die Ausführung der
Dienste erforderlichen Informationen und Materialien liefern (z. B. Zugänge zu Plattformen,
Passwörter, generelle oder konkrete Handlungsanweisungen, zu verwendende Logos, LayoutVorlagen für Textverkehr oder
sonstige vereinbarte Beistellungen des Kunden).`}</p>
      <p>{`(3) Der Kunde ist verpflichtet, erforderliche Materialien in einem gängigen, unmittelbar
verwertbaren digitalen Format zu übergeben. Der Kunde stellt sicher, dass die erforderlichen
Nutzungsrechte eingeräumt werden, insbesondere auch Vervielfältigungs-, Verbreitungs- und
Bearbeitungsrechte im für die Dienste der Auftragnehmerin erforderlichen Umfang. Die
Überprüfung der rechtlichen Zulässigkeit im Hinblick auf Immaterialgüter- und Urheberrecht
kann nur von einem Rechtsanwalt vorgenommen werden und ist nicht Gegenstand des
Auftrages.`}</p>
      <p>{`(4) Sofern der Kunde der Auftragnehmerin körperliche oder nicht körperliche Gegenstände,
insbesondere Bild-, Text- oder Tondateien, zur Verfügung stellt, welche die Rechte Dritter
verletzen, ist der Kunde verpflichtet, die Auftragnehmerin auf erstes Anfordern von jeglicher
Inanspruchnahme Dritter frei zu halten. Dies umfasst insbesondere auch die Kosten der
Rechtsverfolgung.`}</p>
      <p>{`(5) Der Kunde ist verpflichtet, im Rahmen der Eigensicherung erforderliche
Datensicherungen selbständig durchzuführen, insbesondere auch vor Auftragsbeginn. Eine
Haftung der Auftragnehmerin für verlorene Daten besteht insoweit nicht, als sie bei
ordnungsgemäßer Datensicherung durch den Kunden noch verfügbar wären.`}</p>
      <p>{`(6) Der Kunde ist damit einverstanden, dass die Auftragnehmerin die Leistung für den
Kunden mit dessen Name und Logo als Referenz benennt.`}</p>
      <p>{`(7) Der Kunde wird Mitarbeiter, Subunternehmer oder Dienstnehmer der Auftragnehmerin für
die Dauer von zwei Jahren nicht unmittelbar oder mittelbar abwerben, anstellen, in ein
Dienstverhältnis nehmen oder sonst beschäftigen. Handelt der Kunde schuldhaft wider diese
Verpflichtung, ist er verpflichtet, in jedem Einzelfall eine Vertragsstrafe von 5.000 Euro zu
zahlen. Die Geltendmachung eines weitergehenden Schadens durch die Auftragnehmerin
bleibt vorbehalten, die Vertragsstrafe wird auf den Schadensersatz angerechnet.`}</p>
      <h3>{`§ 6 Annahmeverzug und Haftung des Kunden`}</h3>
      <p>{`(1) Kommt der Kunde mit der Annahme der Dienste der Auftragnehmerin in Verzug, ist er
zur Fortzahlung des Entgeltes bis zur Beendigung des Vertrages verpflichtet. Die
Auftragnehmerin wird sich ersparte Aufwendungen anrechnen lassen sowie dasjenige, was sie
durch anderweitige Verwertung ihrer Arbeitskraft erwirbt. Der Kunde wird darauf
hingewiesen, dass die Auftragnehmerin projektbezogen arbeitet und nicht mehr als eine
bestimmte Anzahl von Projekten gleichzeitig wahrnimmt.`}</p>
      <p>{`(2) Sollten Informationen, Unterlagen oder Vorlagen wie beispielsweise Zugänge, Layouts
oder Handlungsanweisungen nicht rechtzeitig und vollständig vorhanden sein, ist die
Auftragnehmerin berechtigt, mit der Leistung nach § 4 nicht zu beginnen. Vom Kunden zu
vertretende Wartezeiten der Auftragnehmerin oder ihrer Mitarbeiter werden wie Arbeitszeiten
vergütet, wenn eine anderweitige Beschäftigung nicht möglich war.`}</p>
      <p>{`(3) Soweit der Kunde seine Informations- und Mitwirkungspflichten verletzt, ist der Kunde
verpflichtet, entsprechende Mehrkosten zu tragen. Die Auftragnehmerin haftet nicht für
Schäden, die auf die Verletzung von Mitwirkungspflichten oder auf die Überlieferung
falscher oder unvollständiger Informationen zurückzuführen sind.`}</p>
      <h3>{`§ 7 Rechte am Ergebnis`}</h3>
      <p>{`(1) Soweit bei der Leistung der Auftragnehmerin schutzfähige Rechte entstehen, erhält der
Kunde eine einfache Lizenz, das Arbeitsergebnis für die vertragsgemäßen Zwecke zu nutzen.
Wünscht der Kunde eine weitergehende Rechtseinräumung, insbesondere das Recht der
Vervielfältigung, der Zugänglichmachung, der öffentlichen Widergabe, der Veröffentlichung,
der Bearbeitung oder Umgestaltung oder sonstige Verwertungsrechte, sind diese extra zu
vergüten.`}</p>
      <p>{`(2) Soweit der Auftragnehmerin ein Urheberrecht an den Ergebnissen zusteht, ist der Kunde
verpflichtet, die Auftragnehmerin als Urheberin zu benennen, sofern nicht vertraglich ein
Anderes vereinbart ist.`}</p>
      <h3>{`§ 8 Kündigung`}</h3>
      <p>{`(1) Sofern in dem Vertrag eine feste Laufzeit vereinbart ist, gilt diese. Eine Kündigung ist in
diesem Fall nicht möglich. Erfolgt zum Ende einer fest vereinbarten Laufzeit eine Einigung
auf eine neue Festlaufzeit nicht, wird der Vertrag zu einem unbefristeten Vertrag und kann
nach Abs. 2 beendet werden.`}</p>
      <p>{`(2) Ist eine feste Laufzeit nicht vereinbart, kann der Vertrag von jeder der Parteien mit einer
Frist von zwei Wochen zum Monatsende schriftlich gekündigt werden. Das Recht zur
außerordentlichen Kündigung wird hiervon nicht berührt.`}</p>
      <p>{`(3) Kündigt der Kunde den Vertrag, werden die Leistungen der Auftragnehmerin anteilig bis
zum Wirksamwerden der Kündigung abgerechnet. Darüber hinaus ersetzt der Kunde der
Auftragnehmerin diejenigen Kosten, die ihr aus Anlass und zum direkten Zweck der
Durchführung des gekündigten Leistungsumfangs unter Beachtung der kaufmännischen
Sorgfalt nachweislich entstanden sind und im Rahmen des Zumutbaren nicht mehr
vermeidbar waren oder sind (z.B. noch erforderliche Datensicherungen).`}</p>
      <h3>{`§ 9 Haftung`}</h3>
      <p>{`(1) Die Auftragnehmerin haftet gleich aus welchem Rechtsgrund für vorsätzliches und grob
fahrlässiges Verhalten sowie bei einer schuldhaften Verletzung von Leben, Körper und
Gesundheit oder bei einem Verstoß gegen das Produkthaftungsgesetz mit einer zugesicherten
Eigenschaft der Höhe nach unbeschränkt.`}</p>
      <p>{`(2) Bei einer leicht fahrlässigen Vertragsverletzung nicht wesentlicher Vertragspflichten
haftet die Auftragnehmerin gar nicht. Bei der Verletzung nicht wesentlicher Vertragspflichten
ist die Haftung der Auftragnehmerin auf den Ersatz des bei Vertragsschluss vorhersehbaren,
typischen Schadens beschränkt. Wesentliche Vertragspflichten sind solche Pflichten, die
vertragswesentliche Rechtspositionen des Kunden schützen, die dem Kunden also der Vertrag
nach seinem Inhalt und Zweck gerade zu gewähren hat sowie Pflichten, deren Erfüllung die
ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren
Einhaltung der Kunde regelmäßig vertrauen darf.`}</p>
      <p>{`(3) Im Übrigen ist die Haftung der Auftragnehmerin gleich aus welchem Rechtsgrund
ausgeschlossen. Die Haftungsbeschränkung gilt auch zugunsten der Angestellten und
Mitarbeiter der Auftragnehmerin sowie dessen Erfüllungs- und Verrichtungsgehilfen und
Subunternehmer. Eine Umkehr der Beweislast ist mit den vorstehenden Regelungen nicht
verbunden.`}</p>
      <h3>{`§ 10 Vermögensverschlechterung des Kunden`}</h3>
      <p>{`(1) Werden der Auftragnehmerin nach Vertragsschluss Tatsachen bekannt, die die
Zahlungsfähigkeit des Kunden in Frage stellen, ist sie berechtigt, vor der weiteren
Ausführung des Auftrages volle Bezahlung der vereinbarten Vergütung oder die Stellen einer
geeigneten Sicherheitsleistung zu verlangen.`}</p>
      <p>{`(2) Sofern der Kunde mit der Bezahlung einer Rechnung in Verzug gerät, ist die
Auftragnehmerin berechtigt, die weitere Leistung bis zur Zahlung der Vergütung oder
Stellung einer ausreichenden Sicherheit für die restliche Vergütung auszusetzen. Kommt der
Kunde der Aufforderung zur Zahlung oder Stellung einer Sicherheitsleistung nicht binnen
angemessener Frist nach, ist die Auftragnehmerin berechtigt, den Vertrag aus wichtigem
Grund fristlos zu kündigen. Weitergehende Ansprüche der Auftragnehmerin bleiben davon
unberührt.`}</p>
      <h3>{`§ 11 Verschwiegenheit`}</h3>
      <p>{`(1) Einer Geheimhaltungsverpflichtung der Auftragnehmerin unterfallen nur Informationen,
Daten, Pläne oder sonstige Unterlagen des Kunden, die dieser ausdrücklich als geheim
gekennzeichnet hat.`}</p>
      <p>{`(2) Die Geheimhaltungsverpflichtung entfällt, wenn die Information allgemein bekannt ist
oder ohne das Verschulden der Auftragnehmerin allgemein bekannt ist oder wird oder ohne
ihr Verschulden allgemeinen bekannt wird, wenn sie sich die geheimhaltungsbedürftige
Information selbständig und ohne Heranziehung der Informationen des Kunden erarbeiten hat
oder wenn das Gesetz oder eine Behörde aufgrund gesetzlicher Vorschrift eine Offenbarung
verlangt.`}</p>
      <h3>{`§ 12 Mediation`}</h3>
      <p>{`(1) Bei Streitigkeiten aus der Geschäftsbeziehung zwischen Auftragnehmerin und Kunden,
sind die Parteien verpflichtet, eine gütliche Lösung anzustreben. Kommt eine Einigung nicht
zustande, verpflichten sie sich, vor der Inanspruchnahme des Rechtsweges, ihre Differenzen
in einer Mediation zu schlichten. Unberührt bleibt die Möglichkeit eines Eilverfahrens im
Wege des einstweiligen Rechtsschutzes.`}</p>
      <p>{`(2) Beantragt eine Partei eine Mediation bei der anderen Partei, sind beide Parteien
verpflichtet, sich innerhalb von acht Tagen auf einen Mediator zu einigen. Kommt diese
Einigung nicht fristgerecht zustande, ist ein anwaltlicher Mediator – wobei primär solche
Mediatoren gewählt werden sollen, die eine Online-Mediation anbieten - bindend für die
Parteien auf Antrag einer der Parteien von dem Präsidenten der Rechtsanwaltskammer oder
einem Vertreter am Sitz der Auftragnehmerin zu bestimmen. Dies ist auch der Ort der
Mediation, sofern das Kammerpräsidium keinen Vorschlag für eine Online-Mediation macht.
Die Mediationssprache ist Deutsch, es sei denn, alle Beteiligten einigen sich auf eine andere
Sprache.`}</p>
      <p>{`(3) Der Rechtsweg (oder ein alternativ vereinbartes Schiedsverfahren, soweit zutreffend) ist
erst zulässig, wenn die Mediation gescheitert ist, weil (a) die Parteien einvernehmlich die
Mediation für beendet erklären, (b) nach der ersten Mediationsitzung weitere Verhandlungen
von einer Partei verweigert werden, (c) der Mediator die Mediation für gescheitert erklärt
oder (d) eine Einigung nicht binnen 3 Monaten nach Beginn der ersten Mediationssitzung
zustande kommt, soweit die Parteien die Frist nicht einvernehmlich verlängern.`}</p>
      <p>{`(4) Die Kosten einer erfolglosen Mediation sind von den Parteien gegenüber dem Mediator
intern hälftig zu tragen. Ungeachtet dieser Regelung im Verhältnis zum Mediator bleibt es
den Parteien unbenommen, diese Kosten und die einer eventuell begleitenden Rechtsberatung
als Rechtsverfolgungskosten in einem anschließenden Verfahren erstattet zu verlangen, es gilt
dann die jeweilige Streitentscheidung. Kommt eine Einigung zustande, gilt die dabei
vereinbarte Kostenregelung.`}</p>
      <h3>{`§ 13 Datenschutz`}</h3>
      <p>{`(1) Als die nach den datenschutzrechtlichen Bestimmungen verantwortliche Stelle versichert
die Auftragnehmerin, dass die Erhebung, die Speicherung, die Veränderung, die
Übermittlung, die Sperrung, die Löschung und die Nutzung der personenbezogenen Daten des
Kunden bei der Auftragnehmerin zum Schutze der personenbezogenen Daten des Kunden
immer in Übereinstimmung mit den geltenden Datenschutzbestimmungen und übrigen
gesetzlichen Regelungen erfolgt. Eine Weitergabe der personenbezogenen Daten des Kunden
an Dritte erfolgt ausschließlich an die im Rahmen der Vertragsabwicklung beteiligten
Dienstleistungspartner, wie z.B. Subunternehmen, Hoster und sonstige Dritte. In den Fällen
der Weitergabe der personenbezogenen Daten des Kunden an Dritte beschränkt sich der
Umfang der übermittelten Daten auf das erforderliche Minimum. Mit dem Vertragsschluss
erklärt sich der Kunde mit der Erhebung, Verarbeitung und Nutzung seiner
personenbezogenen Daten entsprechend den vorgenannten Hinweisen einverstanden.`}</p>
      <p>{`(2) Der Kunde hat das Recht, unentgeltlich Auskunft zu den zu seiner Person gespeicherten
personenbezogenen Daten zu erhalten. Der Kunde hat ferner das Recht, seine Einwilligung in
die Speicherung seiner personenbezogenen Daten jederzeit mit Wirkung für die Zukunft zu
widerrufen. Im Falle einer entsprechenden Mitteilung werden die zu der Person des Kunden
gespeicherten personenbezogenen Daten gelöscht, es sei denn, die betreffenden Daten werden
zur Erfüllung der Pflichten des geschlossenen Vertragsverhältnisses noch benötigt oder
gesetzliche Regelungen stehen einer Löschung entgegen. In diesem Fall tritt an die Stelle
einer Löschung eine Sperrung der betreffenden personenbezogenen Daten`}</p>
      <h3>{`§ 14 Gerichtsstand, Erfüllungsort`}</h3>
      <p>{`(1) Sofern der Kunde Kaufmann ist, ist der Geschäftssitz der Auftragnehmerin Gerichtsstand,
die Auftragnehmerin ist jedoch berechtigt, den Kunden auch an seinem Wohn- oder
Geschäftssitz zu verklagen.`}</p>
      <p>{`(2) Sofern sich aus der Auftragsbestätigung oder dem Vertrag nicht ein Anderes ergibt, ist der
Geschäftssitz der Auftragnehmerin Erfüllungsort.`}</p>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      